<template>
  <tbody style="border: 1.5px solid #e2e2e2">
    <template v-for="(row, i) in MatrixList">
      <tr :key="i">
        <td
          v-if="i == 0"
          :rowspan="getMaxMatterPerSemester"
          class="formation-area-col"
        >
          <p
            class="wrap-text"
            v-b-tooltip.top.v-secondary.noninteractive="
              FormationArea.description
            "
          >
            <b>{{ FormationArea.name }}</b>
          </p>
        </td>
        <template v-for="(matter, j) in row">
          <template v-if="matter && matter.is_matter == true">
            <template v-if="matter">
              <td
                v-if="
                  (isAnual &&
                    matter.regime == isAnual.id &&
                    matter.plan_level == semesterAmount[j]) ||
                  (isAnual &&
                    matter.regime == isAnual.id &&
                    matter.plan_level == semesterAmount[j] - 1 &&
                    semesterAmount[j] == semesterAmount[0])
                "
                :key="`${i}-${j}`"
                :colspan="
                  semesterAmount[semesterAmount.length - 1] ==
                    matter.plan_level ||
                  semesterAmount[0] - 1 == matter.plan_level
                    ? 1
                    : 2
                "
                class="matter-cel"
                :class="{
                  'dotted-column':
                    EgressProfile.intermediate_output_semester > 0 &&
                    j + 1 == EgressProfile.intermediate_output_semester,
                }"
              >
                <MatterCard
                  :key="matter.id"
                  :Matter="matter"
                  :EgressProfile="EgressProfile"
                  :allow_redirecting_matter="allow_redirecting_matter"
                ></MatterCard>
              </td>
              <template
                v-else-if="
                  isAnual &&
                  matter.regime == isAnual.id &&
                  matter.plan_level < semesterAmount[j]
                "
              ></template>
              <td
                v-else
                :key="`${i}-${j}`"
                class="matter-cel"
                :class="{
                  'dotted-column':
                    EgressProfile.intermediate_output_semester > 0 &&
                    j + 1 == EgressProfile.intermediate_output_semester,
                }"
              >
                <MatterCard
                  :key="matter.id"
                  :Matter="matter"
                  :EgressProfile="EgressProfile"
                  :allow_redirecting_matter="allow_redirecting_matter"
                ></MatterCard>
              </td>
            </template>
            <td
              v-else
              :key="`${i}-${j}`"
              class="matter-cel"
              :class="{
                'dotted-column':
                  EgressProfile.intermediate_output_semester > 0 &&
                  j + 1 == EgressProfile.intermediate_output_semester,
              }"
            ></td>
          </template>
          <template v-else-if="matter && matter.is_matter == false">
            <template v-if="matter">
              <td
                v-if="
                  (matter.init_level == semesterAmount[j] &&
                    matter.end_level - matter.init_level != 0) ||
                  (cycle_id &&
                    matter.end_level - matter.init_level != 0 &&
                    semesterAmount[0] <= matter.end_level &&
                    semesterAmount[0] == semesterAmount[j])
                "
                :key="`${i}-${j}`"
                :colspan="
                  matter.end_level >= semesterAmount[j] &&
                  matter.init_level < semesterAmount[0]
                    ? matter.end_level - semesterAmount[0] + 1
                    : matter.end_level - matter.init_level + 1
                "
                class="matter-cel"
                :class="{
                  'dotted-column':
                    EgressProfile.intermediate_output_semester > 0 &&
                    j + 1 == EgressProfile.intermediate_output_semester,
                }"
              >
                <CourseCard
                  :key="matter.id"
                  :CourseWrapper="matter"
                  :EgressProfile="EgressProfile"
                ></CourseCard>
              </td>
              <template
                v-else-if="
                  matter.end_level - matter.init_level != 0 &&
                  matter.init_level < semesterAmount[j]
                "
              ></template>
              <td
                v-else
                :key="`${i}-${j}`"
                class="matter-cel"
                :class="{
                  'dotted-column':
                    EgressProfile.intermediate_output_semester > 0 &&
                    j + 1 == EgressProfile.intermediate_output_semester,
                }"
              >
                <CourseCard
                  :key="matter.id"
                  :CourseWrapper="matter"
                  :EgressProfile="EgressProfile"
                ></CourseCard>
              </td>
            </template>
            <td
              v-else
              :key="`${i}-${j}`"
              class="matter-cel"
              :class="{
                'dotted-column':
                  EgressProfile.intermediate_output_semester > 0 &&
                  j + 1 == EgressProfile.intermediate_output_semester,
              }"
            ></td>
          </template>
          <td
            v-else
            :key="`${i}-${j}`"
            class="matter-cel"
            :class="{
              'dotted-column':
                EgressProfile.intermediate_output_semester > 0 &&
                j + 1 == EgressProfile.intermediate_output_semester,
            }"
          ></td>
        </template>
      </tr>
    </template>
  </tbody>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "FormationAreaRow",
  components: {
    MatterCard: () => import("./MatterCard"),
    CourseCard: () => import("./CourseCard"),
  },
  props: {
    EgressProfile: {
      type: Object,
      required: true,
    },
    FormationArea: {
      type: Object,
      required: true,
    },
    cycle_id: {
      type: Number,
    },
    allow_redirecting_matter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      cycles: names.CYCLES,
      matters: names.MATTERS,
      regimes: names.REGIMES,
      profile_cycles: names.PROFILE_CYCLES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      courses_wrapper: names.COURSES_WRAPPER,
    }),
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.EgressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.courses_wrapper
        .filter((x) => x.egress_profile == this.EgressProfile.id)
        .forEach((x) => {
          list = list.concat(x.egress_profile_matters);
        });
      return [...new Set(list)];
    },
    coursesWrapperList() {
      let list = [];
      if (this.FormationArea.id != 0)
        list = this.courses_wrapper
          .filter(
            (x) =>
              x.egress_profile == this.EgressProfile.id &&
              x.formation_area == this.FormationArea.id
          )
          .sort(function (a, b) {
            if (a.init_level < b.init_level) return -1;
            if (a.init_level > b.init_level) return 1;
            if (a.end_level < b.end_level) return -1;
            if (a.end_level > b.end_level) return 1;
            return 0;
          });
      else
        list = this.courses_wrapper
          .filter(
            (x) =>
              x.egress_profile == this.EgressProfile.id &&
              x.formation_area == null
          )
          .sort(function (a, b) {
            if (a.init_level < b.init_level) return -1;
            if (a.init_level > b.init_level) return 1;
            if (a.end_level < b.end_level) return -1;
            if (a.end_level > b.end_level) return 1;
            return 0;
          });
      return list;
    },
    matterList() {
      let list = [];
      if (this.FormationArea.id != 0) {
        list = this.egress_profiles_matters.filter(
          (x) =>
            x.egress_profile == this.EgressProfile.id &&
            x.formation_area == this.FormationArea.id &&
            !this.wrapperMatterIds.includes(x.id)
        );
      } else {
        list = this.egress_profiles_matters.filter(
          (x) =>
            x.egress_profile == this.EgressProfile.id &&
            x.formation_area == null &&
            !this.wrapperMatterIds.includes(x.id)
        );
      }
      list.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
      return list;
    },
    FormationAreaList() {
      let list = [];
      this.matterList.forEach((x) => {
        x.is_matter = true;
        list.push(x);
      });
      this.coursesWrapperList.forEach((x) => {
        x.is_matter = false;
        list.push(x);
      });
      return list;
    },
    filteredCycles() {
      if (!this.cycle_id) return this.cycles;
      return this.cycles.filter((x) => x.id == this.cycle_id);
    },
    egressProfileCycleId() {
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.EgressProfile.id) list.push(x.cycle);
      });
      return list;
    },
    semesterAmount() {
      let min = 14;
      let max = 0;
      if (this.egressProfileCycleId.length == 0) {
        min = 1;
        max = this.EgressProfile.semester_amount;
      } else {
        if (!this.cycle_id) {
          this.profile_cycles.forEach((cycle) => {
            if (
              cycle.init_semester < min &&
              cycle.egress_profile == this.EgressProfile.id
            )
              min = cycle.init_semester;
            if (
              cycle.end_semester > max &&
              cycle.egress_profile == this.EgressProfile.id
            )
              max = cycle.end_semester;
          });
        } else {
          this.profile_cycles.forEach((cycle) => {
            if (
              this.cycle_id == cycle.cycle &&
              cycle.init_semester < min &&
              cycle.egress_profile == this.EgressProfile.id
            )
              min = cycle.init_semester;
            if (
              this.cycle_id == cycle.cycle &&
              cycle.end_semester > max &&
              cycle.egress_profile == this.EgressProfile.id
            )
              max = cycle.end_semester;
          });
        }
      }
      if (max > this.EgressProfile.semester_amount) {
        max = this.EgressProfile.semester_amount;
      }
      let list = [];
      for (let index = min; index <= max; index++) {
        list.push(index);
      }
      return list;
    },
    getMaxMatterPerSemester() {
      let max = 1;
      this.semesterAmount.forEach((semester) => {
        let matters = this.FormationAreaList.filter(
          (x) =>
            ((x.plan_level == semester ||
              (x.plan_level == semester - 1 &&
                this.isAnual &&
                x.regime == this.isAnual.id)) &&
              x.is_matter == true) ||
            (x.is_matter == false &&
              (x.init_level == semester ||
                (x.init_level < semester &&
                  x.end_level >= semester &&
                  x.end_level - x.init_level != 0) ||
                (this.cycle_id &&
                  x.end_level >= semester &&
                  x.init_level < this.semesterAmount[0] &&
                  this.semesterAmount[0] == semester)))
        );
        if (matters.length > max) max = matters.length;
      });
      return max;
    },
    MatrixList() {
      let matters_matrix = [];
      for (let i = 0; i < this.getMaxMatterPerSemester; i++) {
        matters_matrix.push(new Array(this.semesterAmount.length));
      }
      for (let col = 0; col < this.semesterAmount.length; col++) {
        let matters = this.FormationAreaList.filter(
          (x) =>
            ((x.plan_level == this.semesterAmount[col] ||
              (this.semesterAmount[0] == this.semesterAmount[col] &&
                x.plan_level == this.semesterAmount[col] - 1 &&
                this.isAnual &&
                x.regime == this.isAnual.id)) &&
              x.is_matter == true) ||
            (x.is_matter == false &&
              (x.init_level == this.semesterAmount[col] ||
                (this.cycle_id &&
                  x.end_level >= this.semesterAmount[col] &&
                  x.init_level < this.semesterAmount[0] &&
                  this.semesterAmount[0] == this.semesterAmount[col])))
        );
        for (let row = 0; row < this.getMaxMatterPerSemester; row++) {
          const tmp_matter = matters[row];
          if (!tmp_matter) continue;
          for (
            let tmp_row = row;
            tmp_row < this.getMaxMatterPerSemester;
            tmp_row++
          ) {
            if (!matters_matrix[tmp_row][col]) {
              matters_matrix[tmp_row][col] = tmp_matter;
              if (tmp_matter.is_matter) {
                if (
                  this.isAnual &&
                  tmp_matter.regime == this.isAnual.id &&
                  col + 1 < this.semesterAmount.length &&
                  tmp_matter.plan_level >= this.semesterAmount[col]
                ) {
                  matters_matrix[tmp_row][col + 1] = tmp_matter;
                }
              } else {
                if (tmp_matter.end_level - tmp_matter.init_level != 0) {
                  const tmp_colspan =
                    this.cycle_id &&
                    tmp_matter.end_level >= this.semesterAmount[col] &&
                    tmp_matter.init_level < this.semesterAmount[0]
                      ? tmp_matter.end_level - this.semesterAmount[0]
                      : tmp_matter.end_level - tmp_matter.init_level;
                  for (let i = 1; i <= tmp_colspan; i++) {
                    if (col + i < this.semesterAmount.length)
                      matters_matrix[tmp_row][col + i] = tmp_matter;
                  }
                }
              }
              break;
            } else if (tmp_row == this.getMaxMatterPerSemester - 1) {
              console.log("No alcanza el número de filas.");
            }
          }
        }
      }
      return matters_matrix;
    },
  },
};
</script>

<style>
.formation-area-col {
  background-color: var(--secondary-color);
  width: 100px;
  text-align: left;
  padding-left: 8px;
  border: 2px solid white;
  color: var(--secondary-font-color);
  font-size: var(--thirth-font-size);
  word-wrap: break-word;
}
/* .dotted-column {
  border-right: 2px dotted #000 !important; 
} */
.dotted-column {
  position: relative;
}
.dotted-column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: repeating-linear-gradient(
    to bottom,
    black 0px,
    black 1px,
    transparent 2px,
    transparent 4px
  );
}
.matter-cel {
  min-width: 80px;
  background: #f1f1f1;
  text-align: -webkit-center;
}
.rotate {
  transform: rotate(-90deg);
  min-height: 100px;
}
@media print {
  /* Ancho de la columna del contenedor de los mattercard al modo imprimir */
  .matter-cel {
    max-width: 165px !important;
  }
  .dotted-column {
    padding-right: 2px;
    padding-left: 2px;
  }
}
</style>